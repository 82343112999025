import axios from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { LabelTypes } from '@outmind/types';
import { ApiQuery, useApiRouteMaker } from '../useApi';

/**
 * Hook used for adding a label on a document
 */
export const useAddLabel = (): UseMutationResult<void, never, Params$AddLabelPayload, string[]> => {
  const queryClient = useQueryClient();

  const route = useApiRouteMaker(ApiQuery.ADD_LABEL)();

  const addLabelMutation = useMutation<void, never, Params$AddLabelPayload, string[]>(
    async ({ documentId, labelId, type }) => {
      await axios({
        data: { documentId, labelId, type },
        method: route.method,
        url: route.url,
        withCredentials: true,
      });
    },
    {
      onError: ({ documentId }, _, previousDocumentLabels = []) => {
        // On error revert document labels to previous value
        queryClient.setQueryData<string[]>(
          [ApiQuery.GET_DOCUMENT_LABELS, documentId],
          previousDocumentLabels,
        );
      },
      onMutate: ({ documentId, labelId, type }): string[] | undefined => {
        // Snapshot previous labels for this document
        const previousDocumentLabels = queryClient.getQueryData<string[]>([
          ApiQuery.GET_DOCUMENT_LABELS,
          documentId,
        ]);
        // Optimistically updates document labels by adding this label
        if (type !== LabelTypes.Bookmark) {
          queryClient.setQueryData<string[]>(
            [ApiQuery.GET_DOCUMENT_LABELS, documentId],
            (labels = []) => [...labels, labelId],
          );
        }
        return previousDocumentLabels;
      },
      onSuccess: () => {
        // Reload search results
        queryClient.invalidateQueries(ApiQuery.SEARCH);
      },
    },
  );

  return addLabelMutation;
};

interface Params$AddLabelPayload {
  documentId: string;
  labelId: string;
  type?: LabelTypes;
}
