import { UpdateState } from '@outmind/types';
import { useQuery, UseQueryResult } from 'react-query';
/**
 * Get the desktop app update state
 */
export const useGetUpdateState = (): UseQueryResult<UpdateState | undefined> => {
  const getUpdateStateQuery = useQuery<UpdateState | undefined>(
    'GET_UPDATE_STATE',
    async () => {
      const updateState = await window.electron?.getUpdateState();
      return updateState;
    },
    { refetchOnWindowFocus: true },
  );

  return getUpdateStateQuery;
};
