import axios from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { ApiQuery, useApiRouteMaker } from '../useApi';
import { Label } from './label';

/**
 * Hook used for adding a label on a document
 */
export const useDeleteLabel = (): UseMutationResult<
  void,
  never,
  Params$DeleteLabelPayload,
  Record<string, Label>
> => {
  const queryClient = useQueryClient();

  const makeRoute = useApiRouteMaker(ApiQuery.DELETE_LABEL);

  const deleteLabelMutation = useMutation<
    void,
    never,
    Params$DeleteLabelPayload,
    Record<string, Label>
  >(
    async ({ labelId }) => {
      const route = makeRoute({ labelId });

      await axios({
        method: route.method,
        url: route.url,
        withCredentials: true,
      });
    },
    {
      onError: (_, __, allLabels = {}) => {
        // On error revert labels to previous value
        queryClient.setQueryData<Record<string, Label>>(ApiQuery.GET_LABELS, allLabels);
      },
      onMutate: async ({ labelId }) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(ApiQuery.GET_LABELS);
        // Snapshot previous labels
        const previousLabels =
          queryClient.getQueryData<Record<string, Label>>(ApiQuery.GET_LABELS) ?? {};
        // Optimistically updates document labels by removing this label
        queryClient.setQueryData<Record<string, Label>>(ApiQuery.GET_LABELS, (labels = {}) => {
          const { [labelId]: _, ...newLabels } = labels;
          return newLabels;
        });
        return previousLabels;
      },
      onSuccess: () => {
        // Reload search results
        queryClient.invalidateQueries(ApiQuery.GET_LABELS);
      },
    },
  );

  return deleteLabelMutation;
};

interface Params$DeleteLabelPayload {
  labelId: string;
}
