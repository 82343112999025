import axios from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { ApiQuery, useApiRouteMaker } from '../useApi';

/**
 * Hook used for removing a label from a document
 */
export const useRemoveLabel = (): UseMutationResult<
  void,
  never,
  Params$RemoveLabelPayload,
  string[]
> => {
  const queryClient = useQueryClient();

  const route = useApiRouteMaker(ApiQuery.REMOVE_LABEL)();

  const removeLabelMutation = useMutation<void, never, Params$RemoveLabelPayload, string[]>(
    async ({ documentId, labelId }) => {
      await axios({
        data: { documentId, labelId },
        method: route.method,
        url: route.url,
        withCredentials: true,
      });
    },
    {
      onError: ({ documentId }, _, previousDocumentLabels = []) => {
        // On error revert document labels to previous value
        queryClient.setQueryData<string[]>(
          [ApiQuery.GET_DOCUMENT_LABELS, documentId],
          previousDocumentLabels,
        );
      },
      onMutate: ({ documentId, labelId }) => {
        // Snapshot previous labels for this document
        const previousDocumentLabels = queryClient.getQueryData<string[]>([
          ApiQuery.GET_DOCUMENT_LABELS,
          documentId,
        ]);
        // Optimistically updates document labels by removing this label
        queryClient.setQueryData<string[]>([ApiQuery.GET_DOCUMENT_LABELS, documentId], (labels) =>
          (labels ?? []).filter((_labelId) => labelId !== _labelId),
        );
        return previousDocumentLabels;
      },
      onSuccess: () => {
        // Reload search results
        queryClient.invalidateQueries(ApiQuery.SEARCH);
      },
    },
  );

  return removeLabelMutation;
};

interface Params$RemoveLabelPayload {
  documentId: string;
  labelId: string;
}
