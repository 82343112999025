import { UserInterface } from '@outmind/types';
import axios from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { ApiQuery, useApiRouteMaker } from '../../useApi';

/**
 * Hook for retrieving the "react-query" mutation used for signing in
 */
export const useSignOut = (): UseMutationResult<void, never, never, void> => {
  const queryClient = useQueryClient();

  const makeRoute = useApiRouteMaker(ApiQuery.SIGN_OUT);

  const signOutMutation = useMutation<void, never, never, void>(
    ApiQuery.SIGN_OUT,
    async (): Promise<void> => {
      const route = makeRoute();

      await axios({
        method: route.method,
        url: route.url,
        withCredentials: true,
      });
    },
    {
      onMutate: () => {
        queryClient.setQueryData<UserInterface | undefined>(
          ApiQuery.GET_MY_PROFILE,
          () => undefined,
        );
      },
    },
  );

  return signOutMutation;
};
