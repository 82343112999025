import { LabelInterface } from '@outmind/types';
import axios from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { ApiQuery, useApiRouteMaker } from '../useApi';
import { Label } from './label';

/**
 * Hook used for updating a label
 */
export const useUpdateLabel = (): UseMutationResult<
  void,
  unknown,
  Params$UpdateLabel,
  Record<string, Label>
> => {
  const queryClient = useQueryClient();

  const makeRoute = useApiRouteMaker(ApiQuery.UPDATE_LABEL);

  const updateConnectorMutation = useMutation<
    void,
    unknown,
    Params$UpdateLabel,
    Record<string, Label>
  >(
    async ({ label: { id: labelId, ...label } }) => {
      const route = makeRoute({ labelId });

      await axios({
        data: label,
        method: route.method,
        url: route.url,
        withCredentials: true,
      });
    },
    {
      onError: (_, __, labels = {}) => {
        // On error revert document labels to previous value
        queryClient.setQueryData<Record<string, Label>>(ApiQuery.GET_LABELS, labels);
      },
      onMutate: ({ label }) => {
        // Snapshot previous labels
        const previousLabels = queryClient.getQueryData<Record<string, Label>>(ApiQuery.GET_LABELS);
        // Optimistically updates labels with this modified label
        queryClient.setQueryData<Record<string, Label>>(ApiQuery.GET_LABELS, (labels) => ({
          ...labels,
          [label.id]: new Label(label),
        }));
        return previousLabels;
      },
      onSettled: () => {
        // Reload labels results
        queryClient.invalidateQueries(ApiQuery.GET_LABELS);
      },
    },
  );

  return updateConnectorMutation;
};

interface Params$UpdateLabel {
  label: LabelInterface;
}
